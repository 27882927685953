import * as React from 'react'
import loadable from '@loadable/component'

const Button = loadable(() => import('/src/components/Basic/Buttons/Button'))
const CtaContainer = loadable(() =>
    import('/src/components/Basic/CtaContainer')
)
const FeatureRadio = loadable(() => import('/src/components/Form/FeatureRadio'))
const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const LargeCard = loadable(() => import('/src/components/Cards/LargeCard'))

const StepHome = () => {
    const identifier = 'home'

    return (
        <LargeCard className={'slick-card'}>
            <FieldSet
                align={'centered'}
                legend={'Are you a homeowner, or do you rent?'}
                groupName={identifier}
            >
                <FieldSpacer>
                    <FeatureRadio
                        label={'Homeowner'}
                        groupName={identifier}
                        name={`${identifier}Mortgage`}
                        value={'mortgage'}
                        icon={'family'}
                    />
                </FieldSpacer>

                <FieldSpacer>
                    <FeatureRadio
                        label={'Rent'}
                        groupName={identifier}
                        name={`${identifier}Rent`}
                        value={'rent'}
                        icon={'home'}
                    />
                </FieldSpacer>
            </FieldSet>

            <CtaContainer align={'centered'}>
                <Button type={'submit'} size={'lg'} icon={'chevron'}>
                    Next
                </Button>
            </CtaContainer>
        </LargeCard>
    )
}

export default StepHome
